var OK = OK || {};

OK.appEdit = (function (win) {

// Listeners ---------------------------------------------------->
    /**
     * Crossbrowser addEventListener
     * @param hook
     * @param event
     * @param func
     * @param args
     */
    function addListener(hook, event, func, args) {
        var
            doc = win.document,
            elem = doc.getElementById(hook),
            elem_l,
            args = args || [];


        if (!elem) {
            if (hook.nodeType || hook.document) elem = hook;
            else elem = $('.'+ hook.split(' ').join('.'));
        }

        elem_l = elem.length;

        function handler(e) {
            var e = e || win.event,
                target = e.target || e.srcElement,
                argsSum = [target, e].concat(args); // allow to put parameters in handler

            func.apply(this, argsSum);
        }

        function listen(parentNode, event, func) {
            if (win.addEventListener) parentNode.addEventListener(event, func, false);
            else {
                if (event == 'DOMContentLoaded') event = 'load';
                parentNode.attachEvent('on'+ event, func);
            }
        }

        if (elem_l === undefined || hook.document) listen(elem, event, handler);
        else for (var i=0; i<elem_l; i++) listen(elem[i], event, handler);
    }

    return {
        init: function (args) {
            args = args || {};
            addListener($('.api-settings')[0], 'click', function (target, e) {
                var attr = target.getAttribute('data-action');
                if (!attr) return;

                if (attr.match(/formSwitcher/)) {
                    formSwitcher(target, e, '__on');
                }

                if (attr.match(/fillAppType/)) {
                    fillAppType(target, e, 'fillAppType', 'data-appname');
                }

                if (attr.match(/fieldSwitcher/)) {
                    fieldSwitcher(target, e);
                }

                if (attr.match(/disableSwitcher/)) {
                    disableSwitcher(target, e);
                }

                if (attr.match(/showChild/)) {
                    showChild(target, e);
                }
            });

            statusCheck();

            addListener('it __img', 'blur', imgLoad);
            $.each($('.it.__img'), function (key, val) {
                if (val.value) {
                    imgLoad(val);
                }
            });
            addListener('it __callback', 'blur', callbackChange);
            $.each($('.it.__callback'), function (key, val) {
                if (val.value) {
                    callbackChange(val);
                }
            });
        }
    }
// <---------------------------------------------------- Listeners



// Handlers ---------------------------------------------------->
    function formSwitcher (target, e, cls) {
        while(!target.className.match('form_i')) target = target.parentNode;
        classToggle(target, cls);
    }

    function fieldSwitcher (target, e) {
        var data = target.getAttribute('data-hook');

        if (target.type == 'radio') {
            if (fieldSwitcher.inited) {
                if (fieldSwitcher.checked == target) return;
                else fieldSwitcher.checked = target;

                var
                    radios = target.form[target.name],
                    radios_l = radios.length;

                for (var i=0; i<radios_l; i++) {
                    classToggle('api-settings_i', radios[i].getAttribute('data-hook'));
                }
                return;
            } else fieldSwitcher.inited = true;

            if (e.stopPropagation) e.stopPropagation();
            else e.cancelBubble = true;
        }

        classToggle('api-settings_i', data);
    }

    /**
     * Disable forms to allow init manipulations be done
     * @param target
     * @param e
     */
    function disableSwitcher(target, e) {
        var childCtrls = target.parentNode.getElementsByTagName('INPUT');
        if (!childCtrls) return;

        var childCtrls_l = childCtrls.length;
        for (var i=0; i<childCtrls_l; i++) {
            if (childCtrls[i] == target) continue;

            if (childCtrls[i].checked) {
                classToggle('api-settings_i', childCtrls[i].getAttribute('data-hook'));

            }

            if (fieldSwitcher.inited) childCtrls[i].disabled = (childCtrls[i].disabled)? false : true;
        }
    }


    /**
     * Build string about application types are selected
     *
     * @param target
     * @param e {Event}
     * @param filler
     * @param dataHook
     * @param fillerHook
     */
    function fillAppType(target, e, filler, dataHook, fillerHook) {
        var
            holder = document.getElementById(filler),
            fields = document.forms['api-setting'].elements,
            fields_l =  fields.length,
            holder_tx = [],
            field,
            attr,
            textNode;

        if (e.preventDefault) e.preventDefault();
        else e.returnValue = false;

        for (var i=0; i<fields_l; i++) {
            field = fields[i];
            attr = field.getAttribute(dataHook);

            if (attr && field.checked && !field.disabled) holder_tx.push(attr);
        }

        if (holder.control) holder.control.setAttribute(fillerHook, holder_tx.join(', '));

        if (holder.firstChild) holder.removeChild(holder.firstChild);
        textNode = (holder_tx.length)? holder_tx.join(', ') : 'Не выбран ни один тип приложения.';
        holder.appendChild(document.createTextNode(textNode));

    }


    function showChild(target, e) {

        if (showChild.inited) {

            while (!target.className.match(/\bapi-form_ul\b/)) {
                target = target.parentNode;
            }

            classToggle(target, '__show');

        }
    }


    function statusCheck() {
        var
            form = document.forms['api-setting'],
            inputs = form.elements,
            chkArr = [];

        fieldSwitcher.inited = false;
        fieldSwitcher.checked = false;
        showChild.inited = false;

        chkArr.push(document.getElementsByClassName('al form-i_tgl')[1]);
        var i = inputs.length;
        while (i--) {
            var current = inputs[i];
            if (current.checked && current.hasAttribute('data-hook')) {
                chkArr.push(current);
            }
        }

        if (chkArr.length) {
            // click on "change" button
            chkArr.push(document.getElementsByClassName('al form-i_tgl')[0]);

            var i = chkArr.length;
            while (i--) {
                var current = chkArr[i];

                if (current.type == 'radio') {
                    fieldSwitcher.checked =  current;
                    if (!fieldSwitcher.inited) {

// check attribute "checked" support in IE7
                        current.checked = true;
                        fieldSwitcher.inited = true;
                        continue;
                    }
                }

                if (current.type == 'checkbox') current.checked = false;

                // else
                current.click();
            }
        }

        showChild.inited = true;
    }


    function callbackChange(target) {
        var val = target.value;
        document.getElementById('httpsCallbackWarning').style.display = (val && (val.indexOf('https://') == 0)) ? 'none' : 'block';
    }

    /**
     *
     * Load images into related felds after input blurs
     *
     * @param target {node}    event target
     * @param e {event}    optional
     */
    function imgLoad (target) {
        var
            imgHolder = document.getElementById('imgHolder'),
            attr = target.getAttribute('data-hook'),
            val = target.value,
            imgNode,
            img;

        if (!attr) return;
        var $img = $('.'+ attr, imgHolder)[0];
        if (!$img) return; // to prevent toggles on same element

        imgNode = $img.getElementsByTagName('IMG')[0];

        if (val) {
            imgNode.src = 'i/process.gif';

            var userImg = new Image;

            userImg.onload = function () {
                var cw = imgNode.parentNode.clientWidth,
                    ch = imgNode.parentNode.clientHeight,
                    holder = target.parentNode.parentNode;
                imgNode.src = val;
                var valid = (cw == userImg.width) && (ch == userImg.height);
                if (!valid) { // fixme: remove this check after RB updated standard banner to 216x141
                    valid = cw == 216 && ch == 141 && userImg.width == 230 && userImg.height == 150;
                }
                if (!valid) {
                    notifyOfIncorrectSize(target);
                } else {
                    if (holder.classList.contains('__js-error')) {
                        holder.className = holder.className.replace('form_i__error', '');
                    }
                }
            };
            userImg.onerror = function () {
                imgNode.src = '/res/default/Images/api/form/noimage.png';
                notifyOfIncorrectSize(target);
            };

            userImg.src = val;
        } else {
            imgNode.src = '/res/default/Images/api/form/noimage.png';
            notifyOfIncorrectSize(target);
        }

        function notifyOfIncorrectSize(target) {
            var holder = target.parentNode.parentNode;
            if (!holder.classList.contains('form_i__error') || !holder.classList.contains('__js-error')) {
                holder.classList.add('form_i__error', '__js-error');
            }
        }
    }
// <---------------------------------------------------- Handlers


// Helpers ---------------------------------------------------->
    /**
     * Searching target node by id, class or using predefined node,
     * also works with arrays.
     *
     * @param hook = String(class || id) || Node
     * @param cls = String
     * @param first = Boolean
     */
    function classToggle(hook, cls, firstOnly) {
        var
            doc = win.document,
            firstOnly = firstOnly || false,
            elem = doc.getElementById(hook),
            elem_l;

        if (hook.nodeType) elem = hook;
        else if (firstOnly) elem = $('.'+ hook)[0];
        else elem = $('.'+ hook);

        elem_l = elem.length;

        function toggler(elem) {
            var clsArray = elem.className.split(' '),
                clsArray_l = clsArray.length,
                hasClass = false;

            var i = 0;
            while (i < clsArray_l) {
                if (clsArray[i] === cls) {
                    clsArray.splice(i, 1);
                    hasClass = true;
                }
                i++;
            }

            if (hasClass) elem.className = clsArray.join(' ');
            else {
                clsArray.push(cls);
                elem.className = clsArray.join(' ');
            }
        }

        if (!elem_l) toggler(elem);
        else for (var i=0; i<elem_l; i++) toggler(elem[i]);
    }
// <---------------------------------------------------- Helpers

})(window);
